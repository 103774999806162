<template>
<div class="bg-white p-2 rounded-md mt-2">
  <DataTable :value="juzgados.rows"
             class="p-datatable-customers p-datatable-sm text-sm"
             showGridlines
             dataKey="id"
             v-model:filters="filters1"
             filterDisplay="menu"
             responsiveLayout="scroll"
             removableSort
  >
    <template #header>
      <div class="w-full flex">
        <!-- <div class="w-6/12">
            <span class="p-input-icon-left mr-2">
                <i class="pi pi-search mt-4" />
                <InputText class="text-sm" placeholder="Nombre juzgado" />
            </span>
            <Button label="Buscar" />
        </div> -->
        <div class="w-6/12">
            <!-- <div class="flex justify-end">
              <div class="flex">
                <Button type="button"
                        icon="pi pi-filter-slash text-sm"
                        label="Limpiar filtros"
                        class="p-button-outlined text-sm font-medium"
                        @click="clearFilter1()"
                />
              </div>
            </div> -->
        </div>
      </div>
    </template>
    <template #empty>
      No hay datos para mostrar
    </template>

    <Column headerStyle="width: 3rem" style="text-align: center; justify-content: center;" field="fullname" header="Id">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.id }}
        </p>
      </template>
    </Column>

    <Column field="telefono" header="Categoria" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ categorias(data.categoriaId) }}
        </p>
      </template>
    </Column>

    <Column header="Codigo" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.code }}
        </p>
      </template>
    </Column>

    <Column field="docTipoId" header="Nombre" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.nombre }}
        </p>
      </template>
    </Column>

    <Column field="departamento" header="Localización" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.localizacion }}
        </p>
      </template>
    </Column>
    <Column header="Acciones">
      <template #body="{data}">
        <div class="flex gap-4 justify-center" style="text-align: center; justify-content: center;">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning" @click="onEditar(data)" />
          <!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="remove(data)" /> -->
        </div>
      </template>
    </Column>
  </DataTable>
  <Paginator v-model:first="paginator.offset"
             :rows="paginator.limit"
             :totalRecords="personas.count"
             :rowsPerPageOptions="[2,10,20,30]"
             @page="onPage($event)"
  />
</div>
</template>

<script>
import juzgadoStore from '../../../../../store/juzgado.store'
import juzgadoCategoriaStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/juzgadoCategoria.store'
import storeLocalizacion from '@/apps/pharmasan/juridica/procesosJuridicos/store/localizacion.store'
import { computed, onMounted, ref, watch } from 'vue'
import storePersonas from '../../../../../store/personaNatural.store'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import Swal from 'sweetalert2'
import JuzgadoService from '../../../../../services/juzgado.service'
import { useRouter } from 'vue-router'

export default {
  name: 'TablaJuzgados',
  setup () {
    onMounted(async () => {
      juzgadoCategoriaStore.dispatch('getJuzgadoCategorias')
      storeLocalizacion.dispatch('getDeparatamentos')
      searchJuzgado()
      await storePersonas.dispatch('getAll', {
        paginator: paginator.value,
        filters: filters1.value
      })
      await juzgadoStore.dispatch('getPaginate', {
        paginator: paginator.value,
        filters: filters1.value
      })
    })
    const juzgados = computed(() => juzgadoStore.getters.juzgados)
    const juzgadoCategorias = computed(() => juzgadoCategoriaStore.getters._juzgadoCategorias)
    const router = useRouter()
    const _JuzgadoService = ref(new JuzgadoService())
    const personas = computed(() => storePersonas.getters.personas)
    const paginator = ref({
      offset: 0,
      page: 0,
      limit: 10
    })
    const searchJuzgado = () => {
      _JuzgadoService.value.search(paginator.value).then(({ data }) => {
        console.log('juzgados...', data)
      })
    }
    const categorias = (id) => {
      const a = juzgadoCategorias.value.filter(a => a.id === id)
      for (const value of a) {
        return value.nombre
      }
    }
    const filters1 = ref({
      fullname: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      docTipoId: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      numeroIdentificacion: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      telefono: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      }
    })

    const clearFilter1 = () => {
      filters1.value = {
        fullname: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        docTipoId: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        numeroIdentificacion: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        telefono: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        }
      }
    }
    const onEditar = (data) => {
      juzgadoStore.commit('setDefault')
      juzgadoStore.dispatch('findJuzgado', data.id).then(() => {
        router.push({ name: 'pharmasan.administrativa.juridica.juzgados.editar', params: { id: data.id } })
      })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      paginator.value.offset = first
      paginator.value.page = page
      storePersonas.dispatch('getAll', {
        paginator: paginator.value,
        filters: filters1.value
      })
    }

    const remove = ({ id }) => {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Si se elimina este juzgado no se puede recuperar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          juzgadoStore.dispatch('removeJuzgado', id).then(async () => {
            await juzgadoStore.dispatch('getPaginate', {
              paginator: paginator.value,
              filters: filters1.value
            })
            Swal.fire(
              'Eliminado!',
              'El juzgado fue eliminado con exito.',
              'success'
            )
          })
        }
      })
    }

    watch(filters1, (a, b) => {
      paginator.value.offset = 0
      paginator.value.page = 0
      paginator.value.limit = 10
      storePersonas.dispatch('getAll', {
        paginator: paginator.value,
        filters: filters1.value
      })
    })

    return {
      juzgados,
      categorias,
      juzgadoCategorias,
      personas,
      filters1,
      paginator,
      clearFilter1,
      onPage,
      remove,
      onEditar
    }
  }
}
</script>

<style scoped>
  ::v-deep(.pi) {
    font-size: 0.8rem;
}
  ::v-deep(.p-column-header-content) {
    justify-content: center;
}
</style>
